import { memo as Memo } from 'react'

//* Helpers
import { config } from 'helpers'

//* Components
import Page from 'components/common/Page'
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Button from 'components/common/Button'

//* Style
import Custom404Style from 'styles/pages/Custom404Style'

const Custom404 = Memo(() => {
    return (
        <Page className={`error-404`}>
            <Container>
                <Custom404Style>
                    <Text
                        className={'h1-L font-montserrat'}
                        text={'pageNotFound'}
                    />
                    <Text
                        className={'h6 font-montserrat subtitle'}
                        text={'pageNotFoundSubtitle'}
                    />
                    <Button
                        text={'goToHomepage'}
                        className={'to-home-button'}
                        url={config.routes.home.path}
                    />
                </Custom404Style>
            </Container>
        </Page>
    )
})

export default Custom404
