import styled from 'styled-components'

const Custom404Style = styled.div`
	--subtitleMargin: var(--sp4x) 0 var(--sp10x) 0;

	display: flex;
	flex-direction: column;
	align-items: center;

	.subtitle {
		margin: var(--subtitleMargin);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--subtitleMargin: var(--sp3x) 0 var(--sp8x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--subtitleMargin: var(--sp3x) 0 var(--sp7x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--subtitleMargin: var(--sp3x) 0 var(--sp7x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--subtitleMargin: var(--sp2x) 0 var(--sp6x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--subtitleMargin: var(--sp2x) 0 var(--sp6x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--subtitleMargin: var(--sp2x) 0 var(--sp6x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--subtitleMargin: var(--sp2x) 0 var(--sp5x) 0;

		.to-home-button {
			width: 100%;
		}
	}
`

export default Custom404Style
